<template>
    <OperatorsChooser v-if="!hideOperators" :filterItem="filterItem" :filterObject="filterObject" :dropdown="dropdown"></OperatorsChooser>

    <template v-if="filterItem.operator.indexOf('between') == -1">
        <input v-if="filterItem.isNullValueOperator"
            disabled
            class="form-control form-control-sm rounded-0"
            :value="filterItem.shortDisabledValue"
            :title="filterItem.label">
        <ODatePicker v-else-if="filterItem.isDate && filterItem.valueType !== 'time'"
                v-model="filterItem.limitedValue" 
                containerClass="dp-override"
                class="form-control form-control-sm rounded-0"
                :callback="setSelectedValue"
                format="Short Date"
                :title="filterItem.label"
                :timePicker="false"
            ></ODatePicker>
        <ODatePicker v-else-if="filterItem.valueType == 'time'"
                v-model="filterItem.limitedValue" 
                containerClass="dp-override"
                class="form-control form-control-sm rounded-0"
                :callback="setSelectedValue"
                format="Short Time"
                :outputTimeString="true"
                :timepickerOnly="true"
        />
        <div v-else class="input-group input-group-sm">
             <NumberEditor v-if="filterItem.isNumber && filterItem.options?.format && filterItem.options?.format.indexOf('%') > -1"
                class="form-control form-control-sm rounded-0"
                v-model="filterItem.expressionValue"
                :format="filterItem.options.format"
                @input="onInput" 
                :title="filterItem.label"
                returnAsString
                v-focus
                @keyup.enter="doSearchEnter"/>
            <input v-else 
                type="search" 
                class="form-control form-control-sm rounded-0" 
                v-model="filterItem.expressionValue" 
                @input="onInput"
                :title="filterItem.label"
                :placeholder="filterItem.placeholder"
                v-focus
                @keyup.enter="doSearchEnter">
                <ODataLookup :data="filterObject.columns.filter(x=>x.type=='number')" 
                    v-if="filterItem.showFieldsSelector" 
                    :title="$t('Select Field to Compare')"
                    :bind="sel=>{filterItem.expressionValue = '['+sel.caption+']';filterItem.selectedValue = '['+sel.name+']' }">
                        <OColumn field="caption" width="130" />
                    <template #target="{target}">
                        <button  :ref="target" class = "input-group-text btn btn-sm btn-link"><i class="bi bi-list"></i></button>
                    </template>
                </ODataLookup>
        
        </div>
    </template>

    <template v-else>
        <div class="d-flex">
            <template v-if="filterItem.isDate">
                <ODatePicker v-model="filterItem.combinedStartValue"
                    :placeholder="$t('From')"
                    containerClass="dp-override d-inline-flex"
                    class="form-control form-control-sm rounded-0"
                    format="Short Date"
                    :timePicker="false"
                    :callback="onStartInput"
                ></ODatePicker>

                <ODatePicker v-model="filterItem.combinedEndValue"
                    :placeholder="$t('To')"
                    containerClass="dp-override d-inline-flex"
                    class="form-control form-control-sm rounded-0"
                    format="Short Date"
                    :timePicker="false"
                    :callback="onEndInput"
                ></ODatePicker>
            </template>
            <template v-else-if="filterItem.valueType =='time'">
                <ODatePicker v-model="filterItem.combinedStartValue"
                    :placeholder="$t('From')"
                    containerClass="dp-override d-inline-flex"
                    class="form-control form-control-sm rounded-0"
                    format="Short Time"
                    :timepickerOnly="true"
                    :outputTimeString="true"
                    :callback="onStartInput"
                />

                <ODatePicker v-model="filterItem.combinedEndValue"
                    :placeholder="$t('To')"
                    containerClass="dp-override d-inline-flex"
                    class="form-control form-control-sm rounded-0"
                    format="Short Time"
                    :timepickerOnly="true"
                    :outputTimeString="true"
                    :callback="onEndInput"
                />
            </template>
            <template v-else-if="filterItem.isNumber && filterItem.options?.format">
                <NumberEditor
                    :placeholder="$t('From')"
                    v-focus returnAsString
                    class="form-control form-control-sm rounded-0 me-1" 
                    v-model="filterItem.combinedStartValue" 
                    :format="filterItem.options.format"
                    @input="onStartInput"/>

                <NumberEditor
                    :placeholder="$t('To')"
                    v-focus returnAsString
                    class="form-control form-control-sm rounded-0 ms-1"
                    v-model="filterItem.combinedEndValue"
                    :format="filterItem.options.format"
                    @input="onEndInput"
                    @keyup.enter="doSearchEnter"/>
            </template>
            <template v-else>
                <input :type="filterItem.isNumber ? 'number' : 'text'"
                    :placeholder="$t('From')"
                    v-focus
                    class="form-control form-control-sm rounded-0 me-1" 
                    v-model="filterItem.combinedStartValue" 
                    @input="onStartInput">

                <input :type="filterItem.isNumber ? 'number' : 'text'"
                    :placeholder="$t('To')"
                    v-focus
                    class="form-control form-control-sm rounded-0 ms-1"
                    v-model="filterItem.combinedEndValue"
                    @input="onEndInput"
                    @keyup.enter="doSearchEnter">
            </template>

        </div>
    </template>
</template>

<script setup>
    import OperatorsChooser from 'o365.vue.components.FilterEditor.OperatorsChooser.vue';
    import { defineAsyncComponent, onMounted } from 'vue';

    const emit = defineEmits(["onInput"]);

    const NumberEditor = defineAsyncComponent(async () => {
        const editors = await import('o365.vue.components.inputEditors.jsx');
        return editors.ONumberEditor;
    });

    const props = defineProps(['filterItem', 'filterObject', 'inputWidth', 'dropdown','hideOperators']);


    if (!props.filterItem.operators.find(x => x.name == props.filterItem.operator) && props.filterItem.operator != 'exists_clause') {
        props.filterItem.operator = props.filterItem.defaultOperator;
    }

    

    const doSearchEnter = (evt) => {
        props.filterObject.apply();
        props.dropdown.close();
    }

    const onInput = (evt) => {
        //if() op from limited list then do nothing, else set operator if differs
        if (props.filterItem.isDate) {
            if (props.filterItem.operator !== props.filterItem.operator) {
                props.filterItem.operator = props.filterItem.operator;
            }

            setSelectedValue();
        } else {
            if(!evt.target.value){
                props.filterItem.selectedValue = null;
                props.filterItem.clear();
                if(props.filterItem.expressionValue){
                    props.filterItem.expressionValue = null;
                }
                 emit("onInput");
                 props.filterObject.apply();
                return;
            }

            const vExpr = props.filterItem.parseValue(props.filterItem.expressionValue);
            if (vExpr) {
                
                props.filterItem.selectedValue = vExpr.value;

                if (vExpr.operator) {
                    props.filterItem.operator = vExpr.operator;
                }
            }
        }
        emit("onInput");
    }

    const onEndInput = () => {
        setBetween(); 
    }

    const onStartInput = () => {
       setBetween();
    }

    const setBetween = () => {
        if (props.filterItem.valueType == "number") {
            props.filterItem.selectedValue = [parseInt(props.filterItem.combinedStartValue),parseInt(props.filterItem.combinedEndValue)];
        } else {
            props.filterItem.selectedValue = [props.filterItem.combinedStartValue,props.filterItem.combinedEndValue];
        }
    }

    const setSelectedValue = (pParam) => {
        if (props.filterItem.dateExpression) props.filterItem.dateExpression = null;

        if (props.filterItem.combinedStartValue) props.filterItem.combinedStartValue = null;

        if (props.filterItem.combinedEndValue) {
            props.filterItem.combinedEndValue = null;
            props.filterItem.expressionValue = null;
        }

        if (props.filterItem.selectedValue !== null && !props.filterItem.limitedValue) {
            props.filterItem.selectedValue = null;
            doSearchEnter();
        } else {
            props.filterItem.selectedValue = props.filterItem.limitedValue;
        }
    }
    onMounted(()=>{
        window['basicFilterItem'] = props.filterItem
        window['basicFilterObject'] = props.filterObject
    })
</script>
